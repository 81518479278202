.Card {
    height: 100px;
    width: 70px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-style: solid;
}

.Card-text {
    width: 100%;
    text-align: center;
}